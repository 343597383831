<template>
	<div>
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<p slot="elList">
				<el-table ref="pcListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
					highlight-current-row>
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
						:fixed="index == 0 && (!config || !config.isDetailDisplay)"
						:min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
						v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName === 'Code'"><span type="text"
									style="text-decoration:underline;color:#1874CD;">{{
                                        scope.row[col.FieldName] }}</span></span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>

<script>
	export default {
		mounted() {},
		data() {
			return {
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					PageParams: {},
					params: {}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				dialogConfig: {
					isDetailDisplay: false,
					isSelectionDisplay: true,
				},
				config: {
					isDetailDisplay: false,
					isSelectionDisplay: true,
				},
			}
		},
		props: {
			customerId: {
				type: String,
				default: null
			},
		},
		watch: {
			customerId: function(newVal, oldVal) {
				if (newVal && newVal != oldVal) {
					this.initialize();
				}
			},
		},
		mounted() {
			this.initialize();
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			onPageChange(param) {
				var _this = this;
				var param = this.Utils.cloneObj(this.queryParam);
				var CustomerId = param.params.CustomerId || this.customerId;
				param.PageParams = {
					CustomerId:CustomerId
				};
				this.$ajax.query("omsapi/contractinfo/getcustomercrpagelist", "post", param, data => {
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;
				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
		},
		components: {}
	}
</script>

<style></style>